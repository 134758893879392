<div class="Auth">
  <div class="Auth__inner">
    <div class="Auth__title-container">
      <div class="Auth__page-title-pre">WELCOME TO</div>
      <div class="Auth__page-title mat-display-3">
        <img style="width: 100px;" src="assets/img/png/logo1.png" alt="" />
      </div>
    </div>
    <div class="Auth__form-container">
      <div class="Auth__form-title mat-display-1 text-center">
        Log In
      </div>
      <form class="AuthForm" [formGroup]="loginForm">
        <mat-error *ngIf="!!errorMsg">
          {{ errorMsg }}
        </mat-error>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            placeholder="Email"
            required
          />
          <mat-error *ngIf="f.email.errors?.required">
            Email is Required
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            placeholder="Password"
            required
          />
          <mat-error *ngIf="f.password.errors?.required">
            Password is Required
          </mat-error>
        </mat-form-field>

        <mat-error class="mb-3" *ngIf="loginForm.errors?.invalidDetails">
          Invalid username and/or password.
        </mat-error>

        <button
          mat-flat-button
          type="submit"
          [disabled]="processing"
          color="primary"
          (click)="onSubmit()"
          routerLinkActive="router-link-active"
          class="mt-2"
        >
          {{ processing ? "LOGGING IN..." : "LOG IN" }}
        </button>
        <p routerLink="/forgot" class="loginpara">Forgot Password</p>
      </form>
    </div>
  </div>
</div>

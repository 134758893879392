import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import {
  DriversMutations,
  StartDriverShiftRequest,
} from "@shared/graphql/mutations/drivers.mutations";
import { formatDriversList } from "@shared/helpers/driver.helper";
import { Driver } from "@shared/models/driver.model";
import { GQLSuccessResponse } from "@shared/models/gql-reponse.model";
import { BehaviorSubject } from "rxjs";

const DRIVERS = "/drivers";
@Injectable({
  providedIn: "root",
})
export class DriverService {
  drivers$: BehaviorSubject<Driver[]>;
  currentDriver$: BehaviorSubject<Driver>;

  constructor(private driverServiceGQL: DriversMutations) {
    this.drivers$ = new BehaviorSubject([]);
    this.currentDriver$ = new BehaviorSubject(null);
  }

  initDriversListSubscription() {
    this.driverServiceGQL
      .driversSubscription()
      .subscribe((response: GQLSuccessResponse) => {
        if (!response.data?.drivers) {
          return;
        }

        const formattedDriversList = formatDriversList(response);
        this.drivers$.next(formattedDriversList);
      });
  }

  assignDriver(payload) {
    return this.driverServiceGQL.assignDriver(payload);
  }

  updateDriver(payload) {
    return this.driverServiceGQL.updateDriver(payload);
  }

  setDriverOnBreak(driverId: string, minutes: number, dispatcherId: string) {
    return this.driverServiceGQL.setDriverOnBreak(
      driverId,
      minutes,
      dispatcherId
    );
  }

  setDriverOnline(request: StartDriverShiftRequest) {
    return this.driverServiceGQL.setDriverOnline(request);
  }

  setDriverOffline(driverId: string) {
    return this.driverServiceGQL.setDriverOffline(driverId);
  }

  resumeWorkFromBreak(driverId: string) {
    return this.driverServiceGQL.resumeWorkFromBreak(driverId);
  }
}

import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { formatDate } from "@angular/common";
@Injectable()
export class CsvExporter {
  private replacer = (key, value) => (value === null ? "" : value);
  private skippedCols = ["location", "items", "segmentedAddress"];
  private fileName = "Order List";
  private locationHeaders = ["address", "place_name", "lat", "lng", "placeId"];
  private addressHeaders = [
    "address_type",
    "formatted_address",
    "area",
    "block",
    "city",
    "houseNumber",
    "nickname",
    "street",
    "notes",
  ];

  exportOrders(data) {
    const today = formatDate(new Date(), "d MMM yy HH:mm", "en");
    let headers = Object.keys(data[0]).filter(
      (n) => !this.skippedCols.includes(n)
    );
    let csv_arr = [];
    data.forEach((obj) => {
      let csv_row = [];
      headers.forEach((header) => {
        csv_row.push(JSON.stringify(obj[header], this.replacer));
      });
      csv_row = [
        ...csv_row,
        ...this.buildLocation(obj.location),
        ...this.buildAddressSegment(obj.segmentedAddress),
      ];
      csv_arr.push(csv_row);
    });
    headers = [...headers, ...this.locationHeaders, ...this.addressHeaders];
    csv_arr.unshift(headers.join(","));
    const csvArray = csv_arr.join("\r\n");
    const blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, this.fileName + " (" + today + ").csv");
  }

  buildLocation(location) {
    if (!location) {
      return Array(5).join(".").split(".");
    }
    return [
      JSON.stringify(location["address"], this.replacer),
      JSON.stringify(location["name"], this.replacer),
      JSON.stringify(location["coords"]["lat"], this.replacer),
      JSON.stringify(location["coords"]["lng"], this.replacer),
      JSON.stringify(location["placeId"], this.replacer),
    ];
  }

  buildAddressSegment(address) {
    if (!address) {
      return Array(9).join(".").split(".");
    }
    return [
      JSON.stringify(address.addressType, this.replacer),
      JSON.stringify(address.formattedAddress, this.replacer),
      JSON.stringify(address.segments?.area, this.replacer),
      JSON.stringify(address.segments?.block, this.replacer),
      JSON.stringify(address.segments?.city, this.replacer),
      JSON.stringify(address.segments?.houseNumber, this.replacer),
      JSON.stringify(address.segments?.nickname, this.replacer),
      JSON.stringify(address.segments?.street, this.replacer),
      JSON.stringify(address.segments?.notes, this.replacer),
    ];
  }
}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@shared/services/auth.service";

@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.scss"],
})
export class OtpComponent implements OnInit {
  isSubmitted: boolean = false;

  otpForm: FormGroup;
  processing = false;
  errorMsg = null;
  email: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private _AuthService: AuthService
  ) {}

  ngOnInit(): void {
    this.otpForm = this.fb.group({
      otp: ["", [Validators.required]],
    });
  }

  verifyOtp() {
    this.isSubmitted = true;

    if (this.isSubmitted && this.otpForm.valid) {
      const obj = {
        code: this.otpForm.value.otp,
        key: localStorage.getItem("email"),
      };
      this._AuthService.postRequest("admin/verifyotp", obj).subscribe(
        (response: any) => {
          console.log("response", response);
          localStorage.setItem("accessToken", response.data.token);
          this._AuthService.successMsg(response.message);

          this.router.navigateByUrl("/changepassword");
        },
        (err: any) => {
          this._AuthService.errorMsg(err.error.message);
        }
      );
    }
  }
  forgotPwd() {
    this._AuthService
      .postRequest("admin/forgotpassword", {
        email: localStorage.getItem("email"),
      })
      .subscribe(
        (response: any) => {
          this._AuthService.successMsg(response.message);
        },
        (err: any) => {
          this._AuthService.errorMsg(err.error.message);
        }
      );
  }
  phoneNoInput(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }
}

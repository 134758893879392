import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
})
export class ForgotComponent implements OnInit {
  forgotPwdForm: FormGroup;
  processing = false;
  errorMsg = null;
  isSubmitted: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.forgotPwdForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
    });
  }
  forgotPwd() {
    this.isSubmitted = true;
    if (this.isSubmitted && this.forgotPwdForm.valid) {
      this._authService
        .postRequest("admin/forgotpassword", this.forgotPwdForm.value)
        .subscribe(
          (response: any) => {
            localStorage.setItem("email", this.forgotPwdForm.value.email);

            localStorage.setItem("forgotemail", this.forgotPwdForm.value.email);
            this._authService.successMsg(response.message);

            this.router.navigate(["/otp"]);
          },
          (err: any) => {
            this._authService.errorMsg(err.error.message);
          }
        );
    }
  }

  get f(): any {
    return this.forgotPwdForm.controls;
  }
}

import { Injectable } from "@angular/core";
import { getUserDayStart } from "@shared/utils/utils";
import gql from "graphql-tag";
import { GraphqlService } from "../graphql.service";

const DELIVERY_TRANSACTIONS_SUBSCRIPTION = gql`
  subscription DeliveryTransactionsSubscription(
    $date_created: timestamp!
    $scheduled_arrival_date: String!
  ) {
    transactions(
      order_by: { created_at: desc }
      where: {
        _or: [
          # scheduled orders today -- note: not considering the spill-over during UTC midnight yet
          { delivery_attributes: { value: { _like: $scheduled_arrival_date } } }
          # immediate orders
          { created_at: { _gte: $date_created } }
          # past orders that are still open
          {
            _and: [
              { created_at: { _lt: $date_created } }
              {
                delivery_statuses: {
                  _and: [
                    { is_active: { _eq: true } }
                    {
                      name: {
                        _nin: [
                          "completed"
                          "cancelled"
                          "Completed"
                          "Cancelled"
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ) {
      id
      external_reference
      driver {
        id
        external_account_reference
        first_name
        last_name
        mobile_number
        vehicle_type
        mobile_number_country_code
        location_details
      }
      delivery_statuses {
        is_active
        id
        name
        value
        created_at
        latitude
        longitude
      }
      destinations {
        address
        contact_name
        contact_number
        contact_number_country_code
        destination_type
        id
        is_active
        latitude
        longitude
        sort_order
        package_id
      }
      delivery_attributes {
        attribute
        value
      }
    }
  }
`;

const DELIVERY_TRANSACTIONS_QUERY = gql`
  query DeliveryHistoryQuery($from: timestamp!, $to: timestamp!) {
    transactions(
      order_by: { created_at: desc }
      where: {
        _and: [
          { created_at: { _gte: $from } }
          { created_at: { _lte: $to } }
          {
            delivery_statuses: {
              _and: [
                {
                  name: {
                    _in: ["completed", "cancelled", "Completed", "Cancelled"]
                  }
                }
              ]
            }
          }
        ]
      }
    ) {
      id
      external_reference
      driver {
        id
        external_account_reference
        first_name
        last_name
        mobile_number
        vehicle_type
        mobile_number_country_code
        location_details
      }
      delivery_statuses {
        is_active
        id
        name
        value
        created_at
        latitude
        longitude
      }
      destinations {
        address
        contact_name
        contact_number
        contact_number_country_code
        destination_type
        id
        is_active
        latitude
        longitude
        sort_order
        package_id
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class GQLDeliveryService extends GraphqlService {
  deliveryTransactionsSubsription(): any {
    const userDayStart = getUserDayStart();
    const payload = {
      dateCreated: userDayStart,
      scheduledArrivalDate: `${userDayStart.split("T")[0]}%`,
    };

    return this.deliverySubscription
      .subscribe({
        query: DELIVERY_TRANSACTIONS_SUBSCRIPTION,
        variables: {
          date_created: payload.dateCreated,
          scheduled_arrival_date: payload.scheduledArrivalDate,
        },
      })
      .filter((data) => !!data);
  }

  async getRidersForTransactions(payload) {
    const data = await this.deliverySubscription.query({
      query: DELIVERY_TRANSACTIONS_QUERY,
      variables: {
        from: payload.from,
        to: payload.to,
      },
    });
    return data.data.transactions;
  }
}

import { Injectable } from "@angular/core";
import { GQLDeliveryService } from "@shared/graphql/mutations/delivery.service";
import { OrdersMutations } from "@shared/graphql/mutations/orders.mutations";
import { formatDeliveryTransactions } from "@shared/helpers/delivery.helper";
import {
  formatHistoryStats,
  formatOrder,
  formatOrderHistory,
} from "@shared/helpers/order.helper";
import { GQLSuccessResponse } from "@shared/models/gql-reponse.model";
import { TalinoTransactionStatuses } from "@shared/models/orders.model";
import {
  DateRange,
  getUserDayStart,
  normalizeDateRange,
} from "@shared/utils/utils";
import { BehaviorSubject } from "rxjs";
import { DriverService } from "./driver.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  orders$: BehaviorSubject<any[]>;
  orderHistory$: BehaviorSubject<any[]>;
  currentOrder$: BehaviorSubject<any>;
  activeOrderStatusList$: BehaviorSubject<any>;
  completedOrderStatusList$: BehaviorSubject<any>;

  ordersList$: BehaviorSubject<any[]>;
  orderStatusList$: BehaviorSubject<any[]>;

  rawOrdersResponse$: BehaviorSubject<GQLSuccessResponse>;
  rawOrderHistoryResponse$: BehaviorSubject<GQLSuccessResponse>;

  // ideally in its own service
  deliveryTransactions$: BehaviorSubject<any>;

  // Determines whether or not an existing subscription exists.
  orderSubscription?: ZenObservable.Subscription;
  deliverySubscription?: ZenObservable.Subscription;

  constructor(
    private orderServiceGQL: OrdersMutations,
    private deliveryService: GQLDeliveryService,
    private driverService: DriverService
  ) {
    this.orderHistory$ = new BehaviorSubject([]);
    this.ordersList$ = new BehaviorSubject([]);
    this.orderStatusList$ = new BehaviorSubject([]);
    this.rawOrdersResponse$ = new BehaviorSubject(null);
    this.rawOrderHistoryResponse$ = new BehaviorSubject(null);
    this.deliveryTransactions$ = new BehaviorSubject(null);

    // TODO: Avoid creating subscriptions on constructors UNLESS
    // you are completely sure that all dependencies necessary for
    // that subscription is readily available.
    //
    // If unclear, better to separate it into some `init()` method,
    // which you can trigger later (e.g. via event bus).
  }

  init() {
    this.disposeSubscriptions();
    this.createDeliverySubscription();
    this.createOrderSubscription();
  }

  private createDeliverySubscription() {
    console.log("Creating delivery subscription");
    this.deliverySubscription = this.deliveryService
      .deliveryTransactionsSubsription()
      .subscribe((response: GQLSuccessResponse) => {
        const deliveryOrders = formatDeliveryTransactions(response);
        this.deliveryTransactions$.next(deliveryOrders);
        this._updateFormattedOrdersList();
      });

    this.orders$ = new BehaviorSubject(null);
    this.currentOrder$ = new BehaviorSubject(null);
    this.activeOrderStatusList$ = new BehaviorSubject([]);
    this.completedOrderStatusList$ = new BehaviorSubject([]);
  }

  private createOrderSubscription() {
    console.log("Creating order subscription");
    const subscriptionAlreadyExists =
      this.orderSubscription !== null && this.orderSubscription !== undefined;
    const subscriptionStillActive =
      subscriptionAlreadyExists && this.orderSubscription.closed === false;

    // Do nothing if the order subscription is still alive.
    if (subscriptionStillActive) return;

    const userDayStart = getUserDayStart();

    const payload = {
      statuses: TalinoTransactionStatuses.map((status) => status.slug),
      dateCreated: userDayStart,
      scheduledArrivalDate: `${userDayStart.split("T")[0]}%`,
    };

    try {
      // Otherwise, create a new subscription.
      this.orderSubscription = this.orderServiceGQL
        .orderSubscriptions(payload)
        .subscribe((response: GQLSuccessResponse) => {
          this.rawOrdersResponse$.next(response);
          this._updateFormattedOrdersList();
        });
    } catch (e) {
      console.warn("Failed to create order subscription: ", e.message);
    }
  }

  async getOrderHistory(dateRange: DateRange) {
    dateRange = normalizeDateRange(dateRange);
    const payload = {
      statuses: ["completed", "cancelled", "refunded", "refund-requested"],
      from: dateRange.from.toISOString(),
      to: dateRange.to.toISOString(),
    };
    await this.orderServiceGQL
      .getOrderHistory(payload)
      .then((response: GQLSuccessResponse) => {
        this._updateFormattedOrderHistoryList(response.data.transactions);
      });
  }

  public disposeSubscriptions() {
    console.log("Disposing subscriptions");
    this.orderSubscription?.unsubscribe();
    this.deliverySubscription?.unsubscribe();
  }

  pollRunningTime() {
    return setInterval(() => this._updateFormattedOrdersList(), 1000 * 60);
  }

  private _updateFormattedOrderHistoryList(transactions) {
    this.completedOrderStatusList$.next(formatHistoryStats(transactions));
    const { formattedTransactions } = formatOrderHistory(transactions);
    this.orderHistory$.next(formattedTransactions);
  }

  private _updateFormattedOrdersList() {
    const rawOrders = this.rawOrdersResponse$.value;
    const deliveryTransactions = this.deliveryTransactions$.value;
    if (!rawOrders || !deliveryTransactions) {
      return;
    }

    const currentOrderId = this.currentOrder$.value?.id;

    const {
      formattedTransactions,
      activeStatusCounts,
      refreshedCurrentOrder,
    } = formatOrder(rawOrders, deliveryTransactions, currentOrderId);
    this.activeOrderStatusList$.next(activeStatusCounts);
    this.currentOrder$.next(refreshedCurrentOrder);
    this.orders$.next(formattedTransactions);
  }
}

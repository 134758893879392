import { Injectable } from "@angular/core";
import { TransactionStatusType } from "@shared/models/orders.model";
import gql from "graphql-tag";
import { GraphqlService } from "../graphql.service";

const OrderDetails = gql`
  query OrderDetails($status: [String!]) {
    transactions(
      order_by: { created_at: desc }
      where: { statuses: { value: { _in: $status } } }
    ) {
      full_name
      first_name
      id
      external_transaction_reference
      external_payment_reference
      external_account_reference
      email
      deleted_at
      currency_code
      created_at
      amount
      total_amount
      addresses {
        is_default
        latitude
        longitude
        name
        id
        full_address
        deleted_at
        created_at
        type
        address_details
      }
      last_name
      middle_name
      mobile_number
      mobile_number_country_code
      special_instruction
      tenant {
        name
      }
      statuses(order_by: { created_at: desc }) {
        name
        id
        previous_status_id
        transaction_id
        updated_at
        value
        created_at
        deleted_at
        is_active
      }
      items {
        amount
        created_at
        external_reference
        id
        name
        quantity
        special_instructions
        unit_price
        updated_at
        modifier_lists {
          id
          item_id
          name
          sort_order
          created_at
          external_reference
        }
      }
    }
  }
`;

const UpdateOrderStatus = gql`
  mutation UpdateOrderStatus($id: String!, $event: String!) {
    service_update_delivery_status(
      event: $event
      transaction_external_reference_id: $id
    ) {
      transaction_id
      transaction_status_id
    }
  }
`;

const OrdersSubscriptions = gql`
  subscription OrdersSubscriptions(
    $date_created: timestamp!
    $scheduled_arrival_date: String!
  ) {
    transactions(
      order_by: { created_at: desc }
      where: {
        _or: [
          # scheduled orders today -- note: not considering the spill-over during UTC midnight yet
          {
            transaction_attributes: {
              value: { _like: $scheduled_arrival_date }
            }
          }
          # immediate orders
          { created_at: { _gte: $date_created } }
          # past orders that are still open
          {
            _and: [
              { created_at: { _lt: $date_created } }
              {
                statuses: {
                  _and: [
                    { is_active: { _eq: true } }
                    {
                      name: {
                        _nin: [
                          "completed"
                          "cancelled"
                          "Completed"
                          "Cancelled"
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ) {
      short_id
      full_name
      first_name
      id
      external_transaction_reference
      external_payment_reference
      external_account_reference
      email
      deleted_at
      currency_code
      created_at
      amount
      total_amount
      addresses {
        is_default
        latitude
        longitude
        name
        id
        full_address
        deleted_at
        created_at
        type
        address_details
      }
      merchant_address
      merchant_latitude
      merchant_longitude
      mobile_number
      mobile_number_country_code
      tenant {
        external_reference
        name
      }
      special_instruction
      statuses(order_by: { created_at: desc }) {
        name
        id
        previous_status_id
        transaction_id
        updated_at
        value
        created_at
        deleted_at
        is_active
      }
      items {
        amount
        created_at
        external_reference
        id
        name
        quantity
        special_instructions
        unit_price
        updated_at
        modifier_lists {
          external_reference
          id
          name
          modifiers {
            amount
            external_reference
            id
            name
            quantity
            unit_price
          }
        }
      }
      price_modifiers {
        amount
        external_reference
        name
        type
      }
      transaction_attributes {
        attribute
        value
      }
    }
  }
`;

const OrderHistory = gql`
  query OrderHistory($from: String!, $to: String!, $statuses: [String!]) {
    transactions(
      date_range: { start_date: $from, end_date: $to }
      filter: { status_type: $statuses }
    ) {
      id
      txn_short_id
      txn_transaction_id
      txn_created_at
      del_transaction_id
      pay_transaction_id
      driver_name
      driver_contact_number_combined
      driver_contact_number_country_code
      high_risk
      total_amount
      vehicle_type
      customer_name
      customer_contact_number_combined
      order_status
      merchant_name
      merchant_address
      merchant_contact_number_combined
      order_status
      order_details
      order_statuses {
        name
        value
        is_active
        created_at
        details
      }
    }
  }
`;

const CancelOrder = gql`
  mutation CancelOrder($transaction_external_reference_id: String!) {
    service_update_delivery_status(
      event: "dispatcher-cancel"
      transaction_external_reference_id: $transaction_external_reference_id
    ) {
      transaction_id
      transaction_status_id
    }
  }
`;

const RefundOrder = gql`
  mutation RequestRefund($transaction_id: String!) {
    service_update_transaction_status(
      event: "request-refund"
      id: $transaction_id
    ) {
      id
    }
  }
`;

type UpdateTransactionStatusResponse = {
  service_update_transaction_status: {
    id: string;
  };
};

type UpdateDeliveryStatusResponse = {
  service_update_delivery_status: {
    transaction_id;
    transaction_status_id;
  };
};

@Injectable()
export class OrdersMutations extends GraphqlService {
  updateOrderStatus(payload: any): any {
    type UpdateOrderStatusResponse = UpdateDeliveryStatusResponse;

    return this.deliveryService.mutate<UpdateOrderStatusResponse>({
      mutation: UpdateOrderStatus,
      variables: {
        id: payload.id,
        event: payload.event,
      },
    });
  }

  refundOrder(externalTransactionID: string) {
    type RefundOrderResponse = UpdateTransactionStatusResponse;

    return this.transactionService.mutate<RefundOrderResponse>({
      mutation: RefundOrder,
      variables: {
        transaction_id: externalTransactionID,
      },
    });
  }

  cancelOrder(externalTransactionID: string) {
    type CancelOrderResponse = UpdateDeliveryStatusResponse;

    return this.deliveryService.mutate<CancelOrderResponse>({
      mutation: CancelOrder,
      variables: {
        transaction_external_reference_id: externalTransactionID,
      },
    });
  }

  getOrderHistory(payload): any {
    return this.composerService.query({
      query: OrderHistory,
      variables: {
        from: payload.from,
        to: payload.to,
        statuses: payload.statuses,
      },
    });
  }

  /**
   * This method generates a new subscription for
   * @param payload
   */
  orderSubscriptions(payload: CreateOrderSubscriptionParams) {
    return this.getTransactionSubscription("dinein-admin")
      .subscribe({
        query: OrdersSubscriptions,
        variables: {
          date_created: payload.dateCreated,
          scheduled_arrival_date: payload.scheduledArrivalDate,
        },
      })
      .filter((data) => !!data);
  }
}

export interface CreateOrderSubscriptionParams {
  statuses: TransactionStatusType[];
  dateCreated: string;
  scheduledArrivalDate: string;
}

import { Driver } from "@shared/models/driver.model";
import { GQLSuccessResponse } from "@shared/models/gql-reponse.model";
import { formatDateTimeZone, getTimeDifference } from "@shared/utils/utils";

/**
 * Should return any of the following:
 * - online
 * - working
 * - on-break
 * - offline
 * - shift-ending
 *   - this is when the driver is available and running total is
 *     near the allotted duration for the online shift
 *
 * @param latestTimeLog
 * @param runningDuration
 * @param transactions
 */
const getDriverStatus = (latestTimeLog, runningDuration, transactions) => {
  if (!latestTimeLog?.length) {
    return "offline";
  }

  // possible values: "online", "on-break", "online-ext" (tentative)
  const currentShiftType = latestTimeLog[0].shift.type;
  if (currentShiftType === "on-break") {
    return "on-break";
  }

  if (transactions.length) {
    return "working";
  }

  const targetDurationForShift = latestTimeLog[0].shift.duration_minutes;
  let currentRunningDuration =
    runningDuration.aggregate.sum.duration_minutes || 0;
  const latestTimeLogStartLocalEpoch = new Date(
    formatDateTimeZone(latestTimeLog[0].start_time)
  ).getTime();
  const timeNowEpoch = new Date().getTime();
  const { minutes } = getTimeDifference(
    timeNowEpoch,
    latestTimeLogStartLocalEpoch
  );
  currentRunningDuration += minutes + 1; // Add an extra 1 to watch out for extra seconds
  const remainingMinutes =
    targetDurationForShift - currentRunningDuration + minutes + 1;

  if (remainingMinutes <= 10) {
    return "shift-ending";
  }

  return "online";
};

// I'd rather have it this way than manually compute for the extra values on the fly,
// that is, calling a function from the template such as {{ getStatus() }}--that's more
// expensive in terms of performance, as that would trigger the function on every event // /// done to the table change
export const formatDriversList = (response: GQLSuccessResponse): Driver[] => {
  if (!response.data?.drivers?.length) {
    return [];
  }

  const rawDriversList = response.data.drivers;
  return rawDriversList.map((rawDriverObj) => {
    const {
      id,
      first_name,
      external_account_reference,
      end_time,
      start_time,
      vehicle_type,
      updated_at,
      mobile_number_country_code,
      mobile_number,
      last_name,
      latest_time_log,
      running_duration,
      transactions,
      location_details,
    } = rawDriverObj;

    const driverStatus = getDriverStatus(
      latest_time_log,
      running_duration,
      transactions
    );
    return {
      id,
      firstName: first_name,
      lastName: last_name,
      mobileNumber: mobile_number,
      mobileNumberCountryCode: mobile_number_country_code || "973",
      startTime: start_time,
      endTime: end_time,
      externalId: external_account_reference,
      vehicleType: vehicle_type,
      driverStatus,
      locationDetails: location_details,
      updatedAt: formatDateTimeZone(updated_at),
    };
  });
};

<div class="py-4">
  <div class="font-weight-bold text-uppercase">
    Comments
  </div>

  <div class="mt-4" *ngIf="tickets.length">
    <div class="row py-3">
      <small class="col-8 font-weight-bold text-uppercase">
        Message
      </small>
      <small class="col-4 text-center font-weight-bold text-uppercase">
        Date
      </small>
    </div>
    <div class="StatusRow row py-3" *ngFor="let ticket of tickets">
      <small class="col-8 text-wrap text-break">
        {{ ticket.message }}
      </small>
      <small class="col-4 text-center">
        {{ ticket.created_at | date: "dd-MMM-yyyy" }} <br />
        {{ ticket.created_at | date: "hh:mm:ss a" }}
      </small>
    </div>
  </div>
</div>

<form [formGroup]="ticketForm">
  <div class="mb-2 d-flex">
    <mat-form-field class="w-100" appearance="standard">
      <textarea
        matInput
        maxlength="250"
        formControlName="message"
        placeholder="Dispatcher's notes or comments on the order..."
      ></textarea>
      <mat-hint>{{ ticketForm.get("message").value.length }}/250</mat-hint>
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-end">
    <button
      mat-flat-button
      color="primary"
      class="add-cta my-auto ml-3"
      [disabled]="!ticketForm.valid && loading"
      (click)="save()"
    >
      {{ loading ? "Saving..." : "Add Comment" }}
    </button>
  </div>
</form>

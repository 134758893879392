import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "./modules/material.module";
// import { AudioAlertComponent } from "./components/audio-alert/audio-alert.component";
import { TicketFormComponent } from "./components/ticket-form/ticket-form.component";

import { FixDecimalPlacePipe } from "./pipes/fix-decimal-place.pipe";
import { LuxonDatePipe } from "./pipes/luxon-date.pipe";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { NumberDotOnlyDirective } from "./directives/number-dot-only.directive";

const components = [
  FixDecimalPlacePipe,
  // AudioAlertComponent,
  TicketFormComponent,
  LuxonDatePipe,
];

const modules = [
  CommonModule,
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [...components, NumberOnlyDirective, NumberDotOnlyDirective],
  imports: [...modules],
  exports: [
    ...components,
    ...modules,
    NumberOnlyDirective,
    NumberDotOnlyDirective,
  ],
})
export class SharedModule {}

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-running-dots"
  [fullScreen]="true"
  ><p style="color: white;">Loading...</p></ngx-spinner
>
<div class="chat_fixed">
  <a href="https://dineinhelp.zendesk.com/agent/dashboard" target="_blank">
    <!-- <i class="fa fa-comment" aria-hidden="true"> </i> -->
  </a>
</div>
<router-outlet></router-outlet>

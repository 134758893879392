/**
 * Performs triple equality check to know whether or not the object is
 * null or undefined.
 *
 * @param obj Some object under question, whether it is falsy.
 * @returns true if and only if the object is null or undefined.
 */
export function isNullOrUndefined<E>(
  obj: E | null | undefined
): obj is null | undefined {
  return obj === null || obj === undefined;
}

export const formatMobileNumber = (countryCode: string, mobileNumber: string) =>
  `${countryCode}${mobileNumber}`;

/**
 * Converts date from server to match current browser's timezone
 *
 * @param dateString timestamp / ISO string from postgres db; in UTC
 */
export const formatDateTimeZone = (dateString: string): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Date(`${dateString}Z`).toLocaleString("en-US", { timeZone });
};

/**
 * Retrieves the current timestamp of the user in their local timezone,
 * converts that time object into UTC (+0 GMT), and then hard-codes
 * the time component of the timestamp to zero.
 *
 * E.g.
 *
 * Timestamp with local timezone: "2021-05-09 13:37:16.225 (+8 GMT)"
 * Timestamp in UTC: "2021-05-09T05:37:16.225Z"
 * Return of this function: "2021-05-09T00:00:00.000Z"
 *
 * Convert user's time start to server time / utc 0
 * @param time
 */
export const getUserDayStart = () => {
  return hardCodeDateToUtcTimeZero(new Date());
};

export type DateRange = {
  from: Date;
  to: Date;
};

/**
 * Input:
 * start - 2021-05-01T00:00:00.000Z
 * end   - 2021-05-01T00:00:00.000Z
 *
 * Output:
 * start - 2021-05-01T00:00:00.000Z
 * end   - 2021-05-01T23:59:59.999Z
 *
 * This function allows you to sepcify start and end dates that allow the same day input.
 * For example, entering May 1 (as shown above) shows the time span for May 1 to the end of May 1.
 *
 * @param dateRange Input date range, which contains two Dates.
 * @returns A date range that has its timestamps fixed.
 */
export function normalizeDateRange(dateRange: DateRange): DateRange {
  const secondsInADay = 86400;
  const offset = (secondsInADay - 1) * 1000;

  const result: DateRange = {
    from: new Date(dateRange.from.getTime()),
    to: new Date(dateRange.to.getTime() + offset),
  };
  return result;
}

/**
 * This performs two things:
 *   - formats the date object as ISO 8601 layout
 *   - hard-codes the time element to be equal to the start of the day (assuming UTC).
 *
 * Note: to format an object as ISO 8601 (in UTC format), simply use `Date.toISOString()`.
 *
 * @param dateParams The input date object
 * @returns The date, formatted in ISO8601 layout, with the time fixed at zero (YYYY-MM-ddT00:00:00.000)
 * @author Jadurani
 *
 * Documented and renamed by Darren, to avoid confusion of what the function does.
 * This function was previously named `formatDateToUtc`.
 */
export const hardCodeDateToUtcTimeZero = (dateParams: Date): string => {
  const options = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const [month, date, year] = dateParams
    .toLocaleDateString("en-US", { ...options })
    .split("/");

  const localUserDayStart = new Date(`${year}-${month}-${date}T00:00:00.000`);
  return localUserDayStart.toISOString();
};

export const getEpochTime = (dateString: string): number => {
  return new Date(dateString).getTime();
};

const ONE_DAY_IN_SECONDS = 86400;
const ONE_HOUR_IN_SECONDS = 3600;
const ONE_MINUTE_IN_SECONDS = 60;

const ONE_DAY_IN_HOURS = 24;
const ONE_HOUR_IN_MINUTES = 60;

const ONE_SECOND_IN_MILLISECONDS = 1000;

export const getTimeDifference = (
  futureDateEpoch: number,
  pastDateEpoch: number
) => {
  // get total seconds between the times
  let delta =
    Math.abs(futureDateEpoch - pastDateEpoch) / ONE_SECOND_IN_MILLISECONDS;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / ONE_DAY_IN_SECONDS);
  delta -= days * ONE_DAY_IN_SECONDS;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / ONE_HOUR_IN_SECONDS) % ONE_DAY_IN_HOURS;
  delta -= hours * ONE_HOUR_IN_SECONDS;

  // calculate (and subtract) whole minutes
  const minutes =
    Math.floor(delta / ONE_MINUTE_IN_SECONDS) % ONE_HOUR_IN_MINUTES;
  delta -= minutes * ONE_MINUTE_IN_SECONDS;

  // what's left is seconds
  const seconds = Math.floor(delta % 60); // in theory the modulus is not required

  return { days, hours, minutes, seconds };
};

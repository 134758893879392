import { Injectable } from "@angular/core";
import { HttpLink } from "apollo-angular-link-http";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { ApolloLink } from "apollo-link";
import { KeyCloakAuthService } from "@shared/services/keycloak-auth.service";
import { KeycloakRole } from "@shared/models/graphql.model";

const DEFAULT_CHAR_SET = setContext((operation, context) => ({
  headers: {
    Accept: "charset=utf-8",
  },
}));

/**
 * Should no longer be used.
 * @param adminSecret
 * @deprecated
 * @see GraphqlUtil.createAuthenticatedApolloClient
 */
const HEADER_BYPASS = (adminSecret: string) =>
  setContext((operation, context) => ({
    headers: {
      "x-hasura-admin-secret": adminSecret,
    },
  }));

const NO_ADDITIONAL_HEADERS = () =>
  setContext((operation, context) => ({
    headers: {},
  }));

export type GraphqlClients = {
  client: any;
  client_name: string;
  url: string;
};

@Injectable()
export class GraphqlUtil {
  constructor(
    private keycloakAuthService: KeyCloakAuthService,
    private httpLink: HttpLink
  ) {}

  public createApolloClient(uri: string) {
    return new ApolloClient({
      link: this.createDefaultApolloLink(uri),
      cache: new InMemoryCache(),
    });
  }

  public createDefaultApolloLink(uri: string): ApolloLink {
    return ApolloLink.from([
      DEFAULT_CHAR_SET,
      NO_ADDITIONAL_HEADERS(),
      this.httpLink.create({ uri: uri }),
    ]);
  }

  public createAuthenticatedApolloLink(uri: string): ApolloLink {
    return ApolloLink.from([
      DEFAULT_CHAR_SET,
      this.createApolloLink(),
      this.httpLink.create({ uri: uri }),
    ]);
  }

  public createAuthenticatedApolloClient(uri: string) {
    return new ApolloClient({
      link: this.createAuthenticatedApolloLink(uri),
      cache: new InMemoryCache(),
    });
  }

  /**
   * Generates authentication headers, and optionally assigns the hasura role for this user,
   * using the `X-Hasura-Role` header.
   * @param role
   */
  public generateAuthenticationHeaders(role?: KeycloakRole) {
    // The root cause impurity: this accesses an external state, causing this method
    // to lose idempotency, thus making it more difficult to track its behavior.
    //
    // Reference:
    // https://stackoverflow.com/questions/1077412/what-is-an-idempotent-operation#:~:text=Idempotence%20means%20that%20applying%20an,the%20result%20is%20still%20zero.
    //
    // Recommendation:
    // If a function has a dependency (in this case, the current access token), then to
    // define this function as a pure function, specify that dependency as a parameter to
    // the function.
    const token = this.keycloakAuthService.currentAccessToken.value;

    if (token == null || token == undefined)
      throw new Error(
        "Failed to generate authentication headers. Access token is currently undefined."
      );

    let header = {
      Authorization: `Bearer ${token}`,
    };

    if (role) {
      header["X-Hasura-Role"] = role;
    }
    return header;
  }

  /*
   * Returns HEADER_BYPASS because backend have yet to fix JWT base restrictions and auth
   * @deprecated
   */
  public createApolloLink() {
    // return HEADER_BYPASS("admin");
    return setContext((operation, context) => ({
      headers: this.generateAuthenticationHeaders(),
    }));
  }
}

<div class="Auth">
  <div class="Auth__inner">
    <div class="Auth__title-container">
      <div class="Auth__page-title-pre">WELCOME TO</div>
      <div class="Auth__page-title mat-display-3">
        <img style="width: 100px;" src="assets/img/png/logo1.png" alt="" />
      </div>
    </div>
    <div class="Auth__form-container">
      <!-- <div class="Auth__form-title mat-display-1 text-center">
       Forgot Password
      </div> -->
      <form class="AuthForm" [formGroup]="forgotPwdForm" (submit)="forgotPwd()">
        <mat-error *ngIf="!!errorMsg">
          {{ errorMsg }}
        </mat-error>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            placeholder="Email"
            required
          />
          <mat-error *ngIf="f.email.errors?.required">
            Required
          </mat-error>
        </mat-form-field>
        <button mat-flat-button type="submit" color="primary" class="mt-2">
          Submit
          <!-- {{ processing ? "LOGGING IN..." : "LOG IN" }} -->
        </button>
        <p routerLink="/auth" class="loginpara mt-2">Back to Login</p>
      </form>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig, FirebaseConfig } from "@shared/models/config.model";
import { environment } from "@env/environment";
import { isNullOrUndefined } from "@shared/utils/utils";

@Injectable({
  providedIn: "root",
})
export class AppService {
  configUrl = environment.production
    ? "assets/config.json"
    : "assets/config.local.json";

  constructor(private http: HttpClient) {}

  private configSettings: AppConfig = null;

  get apiUrl(): string {
    if (isNullOrUndefined(this.configSettings?.apiUrl))
      throw new Error(`App not properly configured; missing 'apiUrl'`)
    return this.configSettings?.apiUrl;
  }

  get production(): boolean {
    if (isNullOrUndefined(this.configSettings?.production))
      throw new Error(`App not properly configured; missing 'production'`)
    return this.configSettings?.production;
  }

  get secretAPIKey(): string {
    if (isNullOrUndefined(this.configSettings?.secretAPIKey))
      throw new Error(`App not properly configured; missing 'secretAPIKey'`)
    return this.configSettings?.secretAPIKey;
  }

  get reportsService(): string {
    if (isNullOrUndefined(this.configSettings?.reportsService))
      throw new Error(`App not properly configured; missing 'reportsService'`)
    return this.configSettings?.reportsService;
  }

  get countryCode(): string {
    if (isNullOrUndefined(this.configSettings?.countryCode))
      throw new Error(`App not properly configured; missing 'countryCode'`)
    return this.configSettings?.countryCode;
  }

  get currency(): string {
    if (isNullOrUndefined(this.configSettings?.currency))
      throw new Error(`App not properly configured; missing 'currency'`)
    return this.configSettings?.currency;
  }

  get googleAPIKey(): string {
    if (isNullOrUndefined(this.configSettings?.googleAPIKey))
      throw new Error(`App not properly configured; missing 'googleAPIKey'`)
    return this.configSettings?.googleAPIKey;
  }

  get firebaseConfig(): FirebaseConfig {
    if (isNullOrUndefined(this.configSettings?.firebaseConfig))
      throw new Error(`App not properly configured; missing 'firebaseConfig'`)
    return this.configSettings?.firebaseConfig;
  }

  get hasuraAccount(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.accounts?.url))
      throw new Error(`App not properly configured; missing 'hasura.accounts.url'`)
    return this.configSettings?.hasura?.accounts?.url;
  }

  get hasuraPayment(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.payments?.url))
      throw new Error(`App not properly configured; missing 'hasura.payments.url'`)
    return this.configSettings?.hasura?.payments?.url;
  }

  get hasuraCatalog(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.catalogs?.url))
      throw new Error(`App not properly configured; missing 'hasura.catalogs.url'`)
    return this.configSettings?.hasura?.catalogs?.url;
  }

  get hasuraTransaction(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.transactions?.url))
      throw new Error(`App not properly configured; missing 'hasura.transactions.url'`)
    return this.configSettings?.hasura?.transactions?.url;
  }

  get hasuraDelivery(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.deliveries?.url))
      throw new Error(`App not properly configured; missing 'hasura.deliveries.url'`)
    return this.configSettings?.hasura?.deliveries?.url;
  }

  get hasuraDeliveryWssUrl(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.deliveries?.wssUrl))
      throw new Error(`App not properly configured; missing 'hasura.deliveries.wssUrl'`)
    return this.configSettings?.hasura?.deliveries?.wssUrl;
  }

  get hasuraTransactionWssUrl(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.transactions?.wssUrl))
      throw new Error(`App not properly configured; missing 'hasura.transactions.wssUrl'`)
    return this.configSettings?.hasura?.transactions?.wssUrl;
  }

  get keyCloakRealm(): string {
    if (isNullOrUndefined(this.configSettings?.keyCloakRealm))
      throw new Error(`App not properly configured; missing 'keyCloakRealm'`)
    return this.configSettings?.keyCloakRealm;
  }

  get keyCloakClient(): string {
    if (isNullOrUndefined(this.configSettings?.keyCloakClient))
      throw new Error(`App not properly configured; missing 'keyCloakClient'`)
    return this.configSettings?.keyCloakClient;
  }

  get composerClient(): string {
    if (isNullOrUndefined(this.configSettings?.hasura?.composer?.url))
      throw new Error(`App not properly configured; missing 'hasura.composer.url'`)
    return this.configSettings?.hasura?.composer?.url;
  }

  get hasuraAdminSecret(): string {
    if (isNullOrUndefined(this.configSettings?.hasuraAdminSecret))
      throw new Error(`App not properly configured; missing 'hasuraAdminSecret'`)
    return this.configSettings?.hasuraAdminSecret;
  }

  public load(): Promise<any> {
    if (this.configUrl === "" || isNullOrUndefined(this.configUrl))
      return Promise.reject("Failed to perform `AppService.load` because the config URL was not set.");

    return new Promise((resolve, reject) => {
      this.http.get(this.configUrl).subscribe((response: any) => {
        this.configSettings = response;
        resolve(true);
      }, (err) => {
        console.error("Failed to perform `AppService.load`; see error below");
        console.error(err);
        reject(err);
      });
    });
  }
}

import { Injectable } from "@angular/core";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { AppService } from "@shared/services/app.service";
import { WebSocketLink } from "apollo-link-ws";
import { GraphqlUtil } from "./graphql.util";
import { KeycloakRole } from "@shared/models/graphql.model";

@Injectable()
export class GraphqlService {
  constructor(
    private appService: AppService,
    private graphqlUtil: GraphqlUtil
  ) {}

  get transactionService() {
    return this.graphqlUtil.createAuthenticatedApolloClient(
      this.appService.hasuraTransaction
    );
  }

  get accountService() {
    return this.graphqlUtil.createAuthenticatedApolloClient(
      this.appService.hasuraAccount
    );
  }

  get catalogService() {
    return this.graphqlUtil.createAuthenticatedApolloClient(
      this.appService.hasuraCatalog
    );
  }

  get deliveryService() {
    return this.graphqlUtil.createAuthenticatedApolloClient(
      this.appService.hasuraDelivery
    );
  }

  get composerService() {
    return this.graphqlUtil.createAuthenticatedApolloClient(
      this.appService.composerClient
    );
  }

  get paymentService() {
    return this.graphqlUtil.createAuthenticatedApolloClient(
      this.appService.hasuraPayment
    );
  }

  get authService() {
    return this.graphqlUtil.createApolloClient(this.appService.hasuraAccount);
  }

  getTransactionSubscription(role: KeycloakRole) {
    return generateWSSApolloClient(
      this.appService.hasuraTransactionWssUrl,
      this.graphqlUtil.generateAuthenticationHeaders()
    );
  }

  get deliverySubscription() {
    return generateWSSApolloClient(
      this.appService.hasuraDeliveryWssUrl,
      this.graphqlUtil.generateAuthenticationHeaders()
    );
  }
}

/**
 * Generates a new apollo client connecting via web socket link, using an in memory cache.
 * @param uri The link to the web socket.
 * @param headers headers that include additional details (authentication, role).
 */
const generateWSSApolloClient = (
  uri: string,
  headers: { [key: string]: string }
) => {
  const ws = new WebSocketLink({
    uri: uri,
    options: {
      reconnect: true,
      connectionParams: {
        headers,
      },
      lazy: true,
      minTimeout: 10000,
    },
  });
  const apolloClient = new ApolloClient({
    link: ws,
    cache: new InMemoryCache(),
  });

  return apolloClient;
};

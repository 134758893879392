import { GQLSuccessResponse } from "@shared/models/gql-reponse.model";
import { Order, OrderItem } from "@shared/models/orders.model";
import { HasuraTransaction } from "@shared/models/transaction.model";
import {
  formatDateTimeZone,
  formatMobileNumber,
  getEpochTime,
  getTimeDifference,
} from "@shared/utils/utils";

/**
 * Re-instantiate the status counts template as needed.
 */
const getStatusCountsObj = () => ({
  queued: {
    name: "Finding A Driver",
    count: 0,
    transactions: [],
  },
  "driver-pending": {
    name: "Driver Pending",
    count: 0,
    transactions: [],
  },
  "driver-assigned": {
    name: "Driver Assigned",
    count: 0,
    transactions: [],
  },
  // "arrived-at-pickup": {
  //   name: "Arrived At Kitchen",
  //   count: 0,
  // },
  new: {
    name: "Received By Kitchen",
    count: 0,
    transactions: [],
  },
  confirmed: {
    name: "In-Kitchen",
    count: 0,
    transactions: [],
  },
  // "for-pickup": {
  //   name: "For Collection",
  //   count: 0,
  // },
  // TO DO: align the names of the statuses between transactions
  // and delivery services
  "ready-for-pickup": {
    name: "For Collection",
    count: 0,
    transactions: [],
  },
  "en-route": {
    name: "En Route",
    count: 0,
    transactions: [],
  },
  "arrived-at-customer": {
    name: "Arrived At Customer",
    count: 0,
    transactions: [],
  },
  completed: {
    name: "Completed",
    count: 0,
    transactions: [],
  },
  cancelled: {
    name: "Cancelled",
    count: 0,
    transactions: [],
  },
  refunded: {
    name: "Refunded",
    count: 0,
    transactions: [],
  },
  "refund-requested": {
    name: "Refund Requested",
    count: 0,
    transactions: [],
  },
});

/**
 * Supports transforming the readable name of the status
 * to the machine-centric string value.
 * @param status
 */
function backwardsCompatibility(status: string): string {
  switch (status) {
    case "Queued":
      return "queued";
    case "Driver Pending":
      return "driver-pending";
    case "Driver Assigned":
      return "driver-assigned";
    case "Ready For Pickup":
      return "ready-for-pickup";
    case "Arrived at Pickup":
      return "arrived-at-pickup";
    case "Arrived at Pickup":
      return "arrived-at-customer";
    case "En-route":
      return "en-route";
    case "Completed":
      return "completed";
    case "Cancelled":
      return "cancelled";
  }
  return status;
}

const getDurationString = (timeNow: Date, orderTime: Date): string => {
  const timeNowEpoch = timeNow.getTime();
  const orderTimeEpoch = orderTime.getTime();

  const duration = getTimeDifference(timeNowEpoch, orderTimeEpoch);

  let durationString = "";
  if (duration.days) {
    durationString += `${duration.days} day(s) `;
  }

  if (duration.hours) {
    durationString += `${duration.hours} hr `;
  }

  if (duration.minutes) {
    durationString += `${duration.minutes} min `;
  }

  if (!duration.minutes && duration.seconds) {
    durationString += `${duration.seconds} sec `;
  }

  return durationString || "—";
};

const HRT_MIN_VALUE = 50;

/**
 * Combines the transaction statuses retrieved from both the transactions service
 * and the delivery service. Removes duplicate entries.
 * Both parameters SHOULD BE unfiltered.
 *
 * TO DO: add typechecks
 *
 * @param orderStatuses unfiltered statuses from the transactions service
 * @param deliveryStatuses unfiltered statuses from the delivery service
 */
const formatOrderStatuses = (orderStatuses: any[], deliveryStatuses: any[]) => {
  const formattedOrderStatuses = orderStatuses
    .filter(
      (status) =>
        !["queued", "en-route", "cancelled", "completed"].includes(status.value)
    )
    .map((status) => ({
      createdAt: formatDateTimeZone(status.created_at), // format to browser time
      createdTimestamp: getEpochTime(status.created_at), //epoch time for sorting purposes
      id: status.id,
      isActive: status.is_active,
      name: status.name, // not present in the delivery status
      value: status.value,
      statusType: "order", // internal type-checking, not from DB
    }));

  let combinedStatuses = [...formattedOrderStatuses, ...deliveryStatuses];

  const isAsc = true;
  const sortedStatuses = combinedStatuses.sort((statusA, statusB) => {
    const a = statusA.createdTimestamp;
    const b = statusB.createdTimestamp;

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  });

  return sortedStatuses;
};

const formatOrderHistoryStatuses = (id: string, orderStatuses: any[]) => {
  const formattedOrderStatuses = orderStatuses
    .filter(
      (status) =>
        !["ready-for-pickup", "refund-requested"].includes(status.value)
    )
    .map((status) => ({
      createdAt: formatDateTimeZone(status.created_at),
      createdTimestamp: getEpochTime(status.created_at),
      id: id,
      isActive: status.is_active,
      name: status.name,
      value: status.value,
    }));

  const isAsc = true;
  const sortedStatuses = formattedOrderStatuses.sort((statusA, statusB) => {
    const a = statusA.createdTimestamp;
    const b = statusB.createdTimestamp;

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  });

  return sortedStatuses;
};

const getDriverDetails = (driver) => {
  if (!!driver) {
    return {
      id: driver.id,
      externalId: driver.external_account_reference,
      firstName: driver.first_name,
      lastName: driver.last_name,
      fullName: `${driver.first_name} ${driver.last_name}`,
      vehicleType: driver.vehicle_type,
      mobileNumber: formatMobileNumber(
        driver.mobile_number_country_code || "973", // default for now if null. TO DO: store country code for driver
        driver.mobile_number
      ),
      locationDetails: driver.location_details,
    };
  }

  return null;
};

export const formatOrderHistory = (orders) => {
  if (!orders) {
    return;
  }
  const transactions = orders;

  let activeStatusCounts = getStatusCountsObj();
  const formattedTransactions = transactions.map((currTransaction) => {
    const {
      id,
      txn_short_id,
      customer_contact_number_combined,
      total_amount,
      order_status,
      driver_name,
      driver_contact_number_combined,
      vehicle_type,
      order_statuses,
    } = currTransaction;

    let driver = {
      name: driver_name,
      mobileNumber: driver_contact_number_combined,
      vehicleType: vehicle_type,
    };

    let restaurant = {
      address: {
        latitude: currTransaction?.order_details.merchant_latitude,
        longitude: currTransaction?.order_details.merchant_latitude,
        fullAddress: currTransaction?.order_details.merchant_address,
      },
      mobileNumber: formatMobileNumber(
        currTransaction?.order_details.merchant_number_country_code,
        currTransaction?.order_details.merchant_number
      ),
      name: currTransaction?.order_details.tenant.name,
    };
    const customAddress = currTransaction?.order_details.addresses[0];
    let customer = {
      address: {
        latitude: customAddress.latitude,
        longitude: customAddress.longitude,
        fullAddress: customAddress.full_address,
      },
      mobileNumber: customer_contact_number_combined,
      fullName: currTransaction?.order_details.full_name,
    };

    const orderStatuses = formatOrderHistoryStatuses(id, order_statuses);
    const currentOrderStatus = [...orderStatuses]
      .reverse()
      .find(
        (status) =>
          status.isActive &&
          status.value &&
          !["arrived-at-pickup"].includes(status.value)
      );
    let currentOrderStatusName = "";
    if (!!activeStatusCounts[order_status]) {
      activeStatusCounts[order_status].count += 1;
      const isRefundRequested =
        activeStatusCounts[order_status].name === "Refund Requested";
      currentOrderStatusName = isRefundRequested
        ? "Refunded"
        : activeStatusCounts[order_status].name;
    }
    const amount = currTransaction?.order_details.amount;
    let formattedTransaction = {
      currencyCode: currTransaction?.order_details.currency_code,
      id,
      shortId: txn_short_id,
      externalTransactionId:
        currTransaction?.order_details.external_transaction_reference,
      currentOrderStatus,
      // TO CLEAN LATER
      currentOrderStatusName,
      orderStatuses,
      isHRT: total_amount >= HRT_MIN_VALUE,
      driver,
      restaurant,
      createdAt: formatDateTimeZone(currTransaction?.order_details.created_at),
      customer,
      priceModifiers: currTransaction?.order_details.price_modifiers.map(
        (priceMod) => ({
          amount: priceMod.amount,
          name: priceMod.name,
          type: priceMod.type,
        })
      ),
      specialInstructions: currTransaction?.order_details.special_instruction,
      externalPaymentID:
        currTransaction?.order_details.external_payment_reference,
      amount,
      totalAmount: total_amount,
      items: currTransaction?.order_details.items.map((item) => ({
        amount: item.amount,
        unitPrice: item.unit_price,
        externalId: item.external_reference,
        name: item.name,
        quantity: item.quantity,
        special_instructions: item.special_instructions,
        modifierLists: item.modifier_lists.map((modList) => ({
          name: modList.name,
          modifiers: modList.modifiers.map((mod) => ({
            amount: mod.amount,
            name: mod.name,
            quantity: mod.quantity,
            unitPrice: mod.unit_price,
          })),
        })),
      })),
    };
    return formattedTransaction;
  });
  return {
    formattedTransactions,
    activeStatusCounts,
  };
};

export const formatOrder = (
  response: GQLSuccessResponse,
  deliveryTransactions: any,
  currentOrderId?: string
): {
  formattedTransactions: Order[];
  activeStatusCounts: any;
  refreshedCurrentOrder: any[];
} => {
  if (!response) {
    return;
  }
  const timeNow = new Date();
  const transactions: HasuraTransaction[] = response.data.transactions;
  let refreshedCurrentOrder = null;
  let activeStatusCounts = getStatusCountsObj();

  const formattedTransactions = transactions
    .filter((txn) => deliveryTransactions && deliveryTransactions[txn.id])
    .map((currTransaction) => {
      const {
        id,
        short_id,
        external_transaction_reference,
        external_payment_reference,
        currency_code,
        created_at,
        amount,
        total_amount,
        special_instruction,
        statuses,
        items,
        price_modifiers,
        transaction_attributes,
      } = currTransaction;

      let driver = null;
      let deliveryStatuses = [];
      let restaurant = null;
      let customer = null;

      driver = deliveryTransactions[id].driver;
      deliveryStatuses = deliveryTransactions[id].deliveryStatuses;
      restaurant = deliveryTransactions[id].restaurant;
      customer = deliveryTransactions[id].customer;

      const orderStatuses = formatOrderStatuses(statuses, deliveryStatuses);

      if (orderStatuses && orderStatuses.length === 0) {
        // Cases where the order has no transaction statuses nor delivery statuses...
        // e.g. PayGCC where the payment status is still `authorize_requested` and not yet
        // authorized.
        return null;
      }

      const runningTime = getDurationString(
        timeNow,
        new Date(formatDateTimeZone(created_at))
      );

      // Getting the latest active status from both the order state machine and the
      // delivery state machine. Excluding arrived-at-pickup here as it is a
      // non-blocking status
      const currentOrderStatus = [...orderStatuses]
        .reverse()
        .find(
          (status) =>
            status.isActive &&
            status.value &&
            !["arrived-at-pickup"].includes(status.value)
        );
      let currentOrderStatusName = "";

      if (currentOrderStatus) {
        const value = backwardsCompatibility(currentOrderStatus.value);
        if (!!activeStatusCounts[value]) {
          activeStatusCounts[value].count += 1;
          activeStatusCounts[value].transactions.push(currTransaction);
          currentOrderStatusName = activeStatusCounts[value].name;
        }
      }
      const preOrderData = transaction_attributes?.find(
        (attr) => attr.attribute === "scheduled_arrival_time"
      );

      let formattedTransaction: Order = {
        currencyCode: currency_code,
        id,
        shortId: short_id,
        externalTransactionId: external_transaction_reference,
        currentOrderStatus,
        // TO CLEAN LATER
        currentOrderStatusName,
        orderStatuses,
        isHRT: total_amount >= HRT_MIN_VALUE,
        driver,
        restaurant,
        runningTime,
        createdAt: formatDateTimeZone(created_at),
        customer,
        priceModifiers: price_modifiers.map((priceMod) => ({
          amount: priceMod.amount,
          name: priceMod.name,
          type: priceMod.type,
        })),
        specialInstructions: special_instruction,
        externalPaymentID: external_payment_reference,
        amount,
        preOrderDate: formatDateTimeZone(preOrderData?.value),
        transaction_attributes,
        totalAmount: total_amount,
        items: items
          .map(
            (item): OrderItem => ({
              amount: item.amount,
              unitPrice: item.unit_price,
              externalId: item.external_reference,
              name: item.name,
              quantity: item.quantity,
              special_instructions: item.special_instructions,
              modifierLists: item.modifier_lists.map((modList) => ({
                name: modList.name,
                modifiers: modList.modifiers.map((mod) => ({
                  amount: mod.amount,
                  name: mod.name,
                  quantity: mod.quantity,
                  unitPrice: mod.unit_price,
                })),
              })),
            })
          )
          .map((item) => formatOrderItem(item)),
      };
      if (currentOrderId && currentOrderId === formattedTransaction.id) {
        refreshedCurrentOrder = formattedTransaction;
      }

      return formattedTransaction;
    })
    .filter((t) => t !== null);

  return {
    formattedTransactions,
    activeStatusCounts,
    refreshedCurrentOrder,
  };
};

function formatOrderItem(item: OrderItem): OrderItem {
  const remarks = displayItemModifiersAndSpecialInstructions(item);

  return {
    ...item,
    remarks,
  };
}

export function formatHistoryStats(orders) {
  let stats = [
    {
      value: "completed",
      name: "Completed",
      count: 0,
    },
    {
      value: "cancelled",
      name: "Cancelled",
      count: 0,
    },
    {
      value: "refunded",
      name: "Refunded",
      count: 0,
    },
    {
      value: "refund_requested",
      name: "Refund Requested",
      count: 0,
    },
  ];
  stats.forEach((stat) => {
    orders.forEach((order) => {
      if (order.order_status == stat.value) {
        stat.count += 1;
      }
    });
  });
  return stats;
}

function displayItemModifiersAndSpecialInstructions(item: OrderItem): string {
  const modifiers = item.modifierLists
    .map((ml) => ml.modifiers)
    .filter((m) => m !== undefined && m !== null)
    .reduce((a, b) => [...a, ...b], []);

  const modifiersDescription = modifiers
    .map((m) => `${m.name} (${m.quantity} ${m.quantity !== 1 ? "pcs" : "pc"})`)
    .join(", ");

  const hasModifiers = modifiersDescription.length > 0;
  const hasSpecialInstructions =
    item.special_instructions && item.special_instructions.length > 0;

  if (hasModifiers && !hasSpecialInstructions) {
    return modifiersDescription;
  } else if (!hasModifiers && hasSpecialInstructions) {
    return item.special_instructions;
  }
  return "";
}

import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { GraphqlService } from "../graphql.service";

const InsertTickets = gql`
  mutation InsertTickets(
    $message: String
    $transaction_id: uuid
    $type: String
  ) {
    insert_tickets(
      objects: {
        message: $message
        transaction_id: $transaction_id
        type: $type
      }
    ) {
      returning {
        id
        message
        type
        transaction_id
        updated_at
        external_reference
        created_at
      }
    }
  }
`;

const GetTickets = gql`
  query GetTickets($transaction_id: uuid) {
    tickets(where: { transaction_id: { _eq: $transaction_id } }) {
      external_reference
      id
      message
      transaction_id
      type
      updated_at
      created_at
    }
  }
`;

@Injectable()
export class TicketsMutations extends GraphqlService {
  createTicket(payload: any): any {
    return this.transactionService.mutate({
      mutation: InsertTickets,
      variables: {
        message: payload.message,
        transaction_id: payload.transaction_id,
        type: payload.type,
      },
    });
  }

  getTickets(transactionId): any {
    return this.transactionService.watchQuery({
      query: GetTickets,
      variables: {
        transaction_id: transactionId,
      },
    });
  }
}

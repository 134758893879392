import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError, BehaviorSubject } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AppService } from "./app.service";
import { AuthService } from "./auth.service";
import { AccountsMutation } from "@shared/graphql/mutations/accounts.mutations";
import jwt_decode from "jwt-decode";

const KEYCLOAK_HEADER = {
  headers: new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded"
  ),
};

@Injectable({
  providedIn: "root",
})
export class KeyCloakAuthService {
  public currentAccessToken: BehaviorSubject<String>;
  public accessToken: BehaviorSubject<String>;

  private refreshTokenTimeout;
  private keyCloakRealm: string;
  private jwtPayload: any;

  constructor(private httpClient: HttpClient, private appService: AppService) {
    this.currentAccessToken = new BehaviorSubject(null);
  }

  set refreshToken(token: string) {
    localStorage.setItem("refresh_token", token);
  }

  get refreshToken(): string {
    return localStorage.getItem("refresh_token");
  }

  get getJwtPayload(): any {
    return this.jwtPayload;
  }

  signInUsingToken() {
    if (!this.refreshToken) {
      return of(false);
    }

    let payload = new URLSearchParams();
    payload.set("refresh_token", this.refreshToken);
    payload.set("grant_type", "refresh_token");
    payload.set("client_id", this.appService.keyCloakClient);

    return this.httpClient
      .post(this.keyCloakRealm, payload.toString(), KEYCLOAK_HEADER)
      .subscribe(
        (res) => {
          this.startSession(res);
          return of(true);
        },
        (err) => {
          return this.signOut();
        }
      );
  }

  startSession(response) {
    this.refreshToken = response.refresh_token;
    this.jwtPayload = this.getDecodedAccessToken(response.access_token);
    this.startRefreshTokenTimer();
    this.currentAccessToken.next(response.access_token);
  }

  signOut(): Observable<any> {
    this.stopRefreshTokenTimer();
    this.currentAccessToken.next(null);
    localStorage.removeItem("refresh_token");
    return of(true);
  }

  hasRefreshToken(): boolean {
    return !!this.refreshToken;
  }

  /**
   * Set timer to 8 hours.
   * Access token expiry has been changed to 9 hours and beyond.
   */
  private startRefreshTokenTimer() {
    this.refreshTokenTimeout = setTimeout(
      () => this.signInUsingToken(),
      28800000
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.scss"],
})
export class ChangepasswordComponent implements OnInit {
  changepasswordForm: FormGroup;
  processing = false;
  isSubmitted: boolean = false;
  errorMsg = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private _AuthService: AuthService
  ) {}

  ngOnInit(): void {
    this.changepasswordForm = this.fb.group(
      {
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirmpassword: ["", [Validators.required]],
      },
      {
        validator: this._AuthService.MustMatch("password", "confirmpassword"),
      }
    );
  }
  TokenClear() {
    localStorage.clear();
  }
  updatePwd() {
    this.isSubmitted = true;
    if (this.isSubmitted && this.changepasswordForm.valid) {
      const obj = {
        password: this.changepasswordForm.value.password,
      };
      this._AuthService.postRequest("admin/setpassword", obj).subscribe(
        (response: any) => {
          localStorage.clear();
          this._AuthService.successMsg(response.message);
          this.router.navigate(["/auth"]);
        },
        (err: any) => {
          console.log(err);
          this._AuthService.errorMsg(err.error.message);
        }
      );
    }
  }
}

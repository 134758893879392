import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TicketsMutations } from "@shared/graphql/mutations/tickets.mutations";
import { OrderService } from "@shared/services/order.service";
import { formatDateTimeZone } from "@shared/utils/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "ticket-form",
  templateUrl: "./ticket-form.component.html",
  styleUrls: ["./ticket-form.component.scss"],
})
export class TicketFormComponent implements OnInit, OnDestroy {
  ticketForm: FormGroup;
  processing = false;
  loading: boolean = false;
  currentItem = null;
  order;
  tickets = [];

  private _unsubscribeAll = new Subject();

  constructor(
    private fb: FormBuilder,
    private orderService: OrderService,
    private ticketMutation: TicketsMutations
  ) {}

  ngOnInit() {
    this.orderService.currentOrder$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((currentOrder) => {
        this.order = currentOrder;
        if (!this.ticketForm?.value.message) {
          this.buildForm();
        }
        this.getTickets();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getTickets() {
    if (this.order) {
      this.ticketMutation.getTickets(this.order.id).subscribe((res) => {
        this.tickets = res.data.tickets.map((ticket) => ({
          ...ticket,
          created_at: formatDateTimeZone(ticket.created_at),
        }));
        this.loading = false;
      });
    }
  }

  buildForm() {
    const orderId = this.order ? this.order.id : "";

    this.ticketForm = this.fb.group({
      transaction_id: [orderId, Validators.required],
      message: ["", Validators.required],
      type: ["others", Validators.required], // set to "others"
    });
  }

  save() {
    const txnId = this.ticketForm.value.transaction_id;
    this.ticketForm.markAllAsTouched();

    if (this.ticketForm.invalid) {
      return;
    }

    if (txnId === null || txnId === undefined || txnId === "") {
      console.error("Failed to save ticket; transaction ID was null.");
      return;
    }

    this.loading = true;
    this.ticketMutation
      .createTicket(this.ticketForm.value)
      .then((res) => {
        const newTicket = res.data.insert_tickets.returning[0];
        this.tickets.push({
          ...newTicket,
          created_at: formatDateTimeZone(newTicket.created_at),
        });
        this.ticketForm.controls.message.setValue("");
        this.ticketForm.markAsPristine();
        this.loading = false;
      })
      .catch((res) => {
        console.error(res);
        this.loading = false;
      });
  }
}

import { GQLSuccessResponse } from "@shared/models/gql-reponse.model";
import {
  formatDateTimeZone,
  formatMobileNumber,
  getEpochTime,
} from "@shared/utils/utils";
import { DateTime } from "luxon";

// This helper formats the `transactions` from the delivery service before
// showing the data to the orders table (in /manage-orders).
// For drivers table formatting, refer to driver.helper.ts. The driver details
// formatted here are only for the drivers that are already assigned to an order.

const getDriverDetails = (driver) => {
  if (!!driver) {
    return {
      id: driver.id,
      externalId: driver.external_account_reference,
      firstName: driver.first_name,
      lastName: driver.last_name,
      fullName: `${driver.first_name} ${driver.last_name}`,
      vehicleType: driver.vehicle_type,
      mobileNumber: formatMobileNumber(
        driver.mobile_number_country_code || "973", // default for now if null. TO DO: store country code for driver
        driver.mobile_number
      ),
      locationDetails: driver.location_details,
    };
  }

  return null;
};

/**
 * TODO: Is this intended to return an array? or an object? because it returns both
 * @param response
 */
export const formatDeliveryTransactions = (response: GQLSuccessResponse) => {
  if (!response.data?.transactions?.length) {
    return [];
  }

  const transactions = response.data.transactions;
  let transactionsDict = {};

  transactions.forEach((transaction) => {
    const {
      id,
      external_reference,
      driver,
      delivery_statuses,
      destinations,
    } = transaction;

    // DANGER: if destinations is null or an empty array
    const pickUp = destinations.find(
      (dest) => dest.destination_type === "pick-up"
    );
    const dropOff = destinations.find(
      (dest) => dest.destination_type === "drop-off"
    );

    transactionsDict[external_reference] = {
      id,
      externalId: external_reference,
      driver: getDriverDetails(driver),
      deliveryStatuses: delivery_statuses.map((status) => ({
        createdAt: formatDateTimeZone(status.created_at), // format to browser time
        createdTimestamp: getEpochTime(status.created_at), //epoch time for sorting purposes
        id: status.id,
        name: status.name,
        value: status.value,
        isActive: status.is_active,
        statusType: status.latitude ? "delivery" : "order", // internal type-checking, not from DB
        latitude: status.latitude || null,
        longitude: status.longitude || null,
      })),
      restaurant: {
        address: {
          latitude: pickUp?.latitude,
          longitude: pickUp?.longitude,
          fullAddress: pickUp?.address.full_address,
        },
        mobileNumber: formatMobileNumber(
          pickUp?.contact_number_country_code,
          pickUp?.contact_number
        ),
        name: pickUp?.contact_name,
      },
      customer: {
        address: {
          latitude: dropOff?.latitude,
          longitude: dropOff?.longitude,
          fullAddress: dropOff?.address.full_address,
        },
        mobileNumber: formatMobileNumber(
          dropOff?.contact_number_country_code,
          dropOff?.contact_number
        ),
        name: dropOff?.contact_name,
      },
    };
  });
  return transactionsDict;
};

import { MapsAPILoader } from "@agm/core";
import { Component } from "@angular/core";
// import { AccountsMutation } from "@shared/graphql/mutations/accounts.mutations";
import { AuthService } from "@shared/services/auth.service";
// import { DriverService } from "@shared/services/driver.service";
// import { KeyCloakAuthService } from "@shared/services/keycloak-auth.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  private _unsubscribeAll = new Subject();

  constructor(
    private authService: AuthService,
    private mapsApiLoader: MapsAPILoader
  ) {}

  ngOnInit() {
    this.mapsApiLoader.load();

    // this.keycloakAuthService.currentAccessToken
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((token) => {
    //     if (!!token) {
    //       this.getCurrentUser();
    //       this.driverService.initDriversListSubscription();
    //     } else {
    //       this.authService.currentUser$.next(null);
    //     }
    //   });

    // if (!!this.keycloakAuthService.refreshToken) {
    //   this.keycloakAuthService.signInUsingToken();
    // }
    // this.getCurrentUser();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // getCurrentUser() {
  //   const currentUser = this.authService.currentUser$.value;
  //   if (!!currentUser) {
  //     return;
  //   }
  //   const jwtPayload = this.keycloakAuthService.getJwtPayload;
  //   if (!jwtPayload) {
  //     return;
  //   }
  //   this.accountsMutation
  //     .queryCurrentUser(jwtPayload.hasura["X-Hasura-User-Id"])
  //     .subscribe((data) => {
  //       const userData = data.data.accounts[0];
  //       const currentUser = {
  //         email: userData.email,
  //         id: userData.id,
  //         firstName: userData.first_name,
  //         lastName: userData.last_name,
  //         externalId: userData.external_id,
  //         tenantId: userData.tenant_id,
  //       };
  //       this.authService.currentUser$.next(currentUser);
  //     });
  // }
}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { Router } from "@angular/router";
// import { AuthMutation } from "@shared/graphql/mutations/auth.mutations";
// import { KeyCloakAuthService } from "@shared/services/keycloak-auth.service";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  loginForm: FormGroup;
  processing = false;
  errorMsg = null;

  constructor(
    private _authService: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ["apptunix@yopmail.com", Validators.required],
      password: ["12345678", Validators.required],
    });
  }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    }

    this.processing = true;
    this._authService
      .postRequestwithoutToken("admin/login", this.loginForm.value)
      .subscribe(
        (response: any) => {
          console.log(response);
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("adminData", JSON.stringify(response.data));
          localStorage.setItem("userRole", response["data"].userRole);

          this.router
            .navigateByUrl("/booking/booking/0")
            .then(() => {
              this.loginForm.reset();
            })
            .finally(() => (this.processing = false));
          // this.particularValue=response['data'];
          // this._commService.successMsg(response.message)
          // this.editForm.patchValue({
          //   question:this.particularValue[0].faq[0].question,
          //   answer:this.particularValue[0].faq[0].answer

          // })
        },
        (err: any) => {
          console.log("err", err);
          this.processing = false;
          this.errorMsg = err.error.message;
          // this._commService.errorMsg(err.message)
        }
      );
    // this.authMutation
    //   .login(this.loginForm.value)
    //   .then((res) => {
    //     this.keycloakAuthService.startSession(
    //       res.data.service_login_backoffice_user
    //     );
    //     this.router
    //       .navigateByUrl("dashboard")
    //       .then(() => this.loginForm.reset())
    //       .finally(() => (this.processing = false));
    //   })
    //   .catch((res) => {
    //     this.processing = false;
    //     this.errorMsg = "Invalid Email or Password";
    //   });
  }
  get f(): any {
    return this.loginForm.controls;
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
  name: "luxonDate",
})
export class LuxonDatePipe implements PipeTransform {
  transform(
    value: string,
    format: string = "dd-MMM-yyyy",
    timezone: string = "Asia/Bahrain"
  ): string {
    const dateTime = DateTime.fromISO(value, { zone: "utc" }).setZone(timezone);
    return dateTime.toFormat(format);
  }
}

import { HasuraTransactionAttributes } from "./transaction.model";

/**
 * List of allowed transaction status types, corresponding to their
 * internal values. e.g. `queued` not the human readable form `Queued`.
 *
 */
export type TransactionStatusType =
  | "queued"
  | "new"
  | "confirmed"
  | "for-pickup"
  | "en-route"
  | "completed"
  | "cancelled";

/**
 * List of allowed delivery status types, corresponding to their
 * internal values. e.g. `driver-pending` not the human readable form `Driver Pending`.
 *
 * TODO: Fill up
 */
export type DeliveryStatusType = "" | "" | "";

export type DeliveryStatus = {
  name: string;
  value: DeliveryStatusType;
};

export interface TransactionStatus {
  slug: TransactionStatusType;
  name: string;
}

/**
 * List of all supported transaction statuses.
 */
export const TalinoTransactionStatuses = [
  {
    slug: "",
    name: "All",
  },
  {
    slug: "queued",
    name: "Queued",
  },
  {
    slug: "new",
    name: "New",
  },
  {
    slug: "confirmed",
    name: "In-Kitchen",
  },
  {
    slug: "for-pickup",
    name: "For Collection",
  },
  {
    slug: "en-route",
    name: "En Route",
  },
  {
    slug: "completed",
    name: "Completed",
  },
  {
    slug: "cancelled",
    name: "Cancelled",
  },
] as TransactionStatus[];

export type Order = {
  currencyCode: string;
  id: string;
  shortId: string;
  externalTransactionId: string;
  // TODO: FIX
  currentOrderStatus: any;
  // TODO: FIX
  currentOrderStatusName: any;
  // TODO: FIX
  orderStatuses: any[];
  isHRT: boolean;
  // TODO: FIX
  driver: any;
  // TODO: FIX
  restaurant: any;

  runningTime: string;
  preOrderDate: string;
  createdAt: string;
  // TODO: FIX
  customer: any;
  priceModifiers: any[];
  specialInstructions: string;
  externalPaymentID: string;
  amount: number;
  totalAmount: number;
  items: OrderItem[];
  transaction_attributes: HasuraTransactionAttributes[];
};

export type OrderItem = {
  amount: number;
  unitPrice: number;
  externalId: string;
  name: string;
  quantity: number;
  remarks?: string;
  special_instructions: string;
  modifierLists: OrderModifierList[];
};

export type OrderModifierList = {
  name: string;
  modifiers: OrderModifierItem[];
};

export type OrderModifierItem = {
  name: string;
  amount: number;
  quantity: number;
  unitPrice: number;
};

import { Injectable } from "@angular/core";
import { getUserDayStart } from "@shared/utils/utils";
import gql from "graphql-tag";
import { GraphqlService } from "../graphql.service";

const DRIVERS_SUBSCRIPTION = gql`
  subscription MyQuery(
    $excluded_statuses: [String!] = ""
    $today_start: timestamp!
  ) {
    drivers(
      where: {
        external_account_reference: { _is_null: false }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc }
    ) {
      id
      first_name
      external_account_reference
      end_time
      email
      start_time
      vehicle_type
      updated_at
      mobile_number_country_code
      mobile_number
      last_name
      location_details
      # Get latest active time_log; Gives the duration_minutes we're trying to match
      latest_time_log: time_logs(
        where: { is_active: { _eq: true } }
        limit: 1
        order_by: { created_at: desc }
      ) {
        id
        is_active
        start_time
        end_time
        driver_id
        shift {
          comment
          duration_minutes
          driver_id
          id
          set_by_account_external_reference
          type
          date
        }
      }
      running_duration: time_logs_aggregate(
        where: {
          created_at: { _gte: $today_start }
          shift: { type: { _eq: "online" } }
        }
      ) {
        aggregate {
          sum {
            duration_minutes
          }
        }
      }
      transactions(
        where: {
          delivery_statuses: {
            is_active: { _eq: true }
            name: { _nin: $excluded_statuses }
          }
        }
        limit: 1
        order_by: { created_at: desc }
      ) {
        account_id
        id
        created_at
        delivery_statuses(where: { is_active: { _eq: true } }) {
          is_active
          name
          created_at
          id
          latitude
          longitude
          transaction_id
        }
        destinations {
          contact_name
          contact_number
          contact_number_country_code
          address
          created_at
          destination_type
          id
          is_active
          latitude
          longitude
          transaction_id
        }
        packages {
          details
          email
          id
          created_at
        }
      }
    }
  }
`;

const GetDrivers = gql`
  query GetDrivers($offset: Int, $limit: Int) {
    drivers(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        deleted_at: { _is_null: true }
        external_account_reference: { _is_null: false }
      }
    ) {
      address
      created_at
      deleted_at
      drivers_license_image_back_url
      drivers_license_image_front_url
      drivers_license_number
      email
      first_name
      id
      last_name
      mobile_number
      mobile_number_country_code
      photo_url
      updated_at
    }
    drivers_aggregate(
      where: { external_account_reference: { _is_null: false } }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

const InsertDrivers = gql`
  mutation InsertDrivers(
    $first_name: String
    $email: String
    $last_name: String
    $mobile_number: String
    $mobile_number_country_code: String
    $photo_url: String
    $drivers_license_number: String
  ) {
    insert_drivers(
      objects: {
        first_name: $first_name
        email: $email
        last_name: $last_name
        mobile_number: $mobile_number
        mobile_number_country_code: $mobile_number_country_code
        photo_url: $photo_url
        drivers_license_number: $drivers_license_number
      }
    ) {
      returning {
        created_at
        deleted_at
        drivers_license_image_back_url
        drivers_license_image_front_url
        drivers_license_number
        email
        first_name
        id
        last_name
        mobile_number
        address
        mobile_number_country_code
        photo_url
        updated_at
      }
    }
  }
`;

const UpdateDrivers = gql`
  mutation UpdateDrivers(
    $id: uuid
    $first_name: String
    $email: String
    $last_name: String
    $mobile_number: String
    $mobile_number_country_code: String
    $photo_url: String
    $drivers_license_number: String
    $start_time: String
    $end_time: String
    $vehicle_type: vehicle_types_enum
  ) {
    update_drivers(
      where: { id: { _eq: $id } }
      _set: {
        first_name: $first_name
        email: $email
        last_name: $last_name
        mobile_number: $mobile_number
        mobile_number_country_code: $mobile_number_country_code
        photo_url: $photo_url
        drivers_license_number: $drivers_license_number
        start_time: $start_time
        end_time: $end_time
        vehicle_type: $vehicle_type
      }
    ) {
      returning {
        created_at
        deleted_at
        drivers_license_image_back_url
        drivers_license_image_front_url
        drivers_license_number
        email
        first_name
        id
        last_name
        mobile_number
        address
        mobile_number_country_code
        photo_url
        updated_at
      }
    }
  }
`;

const DeleteDriver = gql`
  mutation DeleteDriver($id: uuid, $deleted_at: timestamp) {
    update_drivers(
      where: { id: { _eq: $id } }
      _set: { deleted_at: $deleted_at }
    ) {
      returning {
        created_at
        deleted_at
        drivers_license_image_back_url
        drivers_license_image_front_url
        drivers_license_number
        email
        first_name
        id
        last_name
        mobile_number
        address
        mobile_number_country_code
        photo_url
        updated_at
      }
    }
  }
`;

const AssignRider = gql`
  mutation AssignRider($id: uuid!, $driver_id: uuid!) {
    service_assign_driver(driver_id: $driver_id, transaction_id: $id) {
      transaction_id
      driver_id
    }
  }
`;

const GetDriverAssignment = gql`
  query GetDriverAssignment($id: String) {
    transactions(where: { external_reference: { _eq: $id } }) {
      id
      driver_id
    }
  }
`;

export type SetDriverOnBreakResponse = {
  service_start_driver_break: {
    shift_id: string;
    time_log_id: string;
  };
};

const SET_DRIVER_ON_BREAK = gql`
  mutation SetDriverOnBreak(
    $driver_id: uuid!
    $duration_minutes: Int!
    $comment: String! = ""
    $set_by_account_external_reference: String!
  ) {
    service_start_driver_break(
      driver_id: $driver_id
      duration_minutes: $duration_minutes
      set_by_account_external_reference: $set_by_account_external_reference
      comment: $comment
    ) {
      shift_id
      time_log_id
    }
  }
`;

export type StartDriverShiftRequest = {
  driver_id: string;
  // Optional- overridden from the backend
  set_by_account_external_reference: string;
  duration_minutes: number;
  type: "online" | "on-break";
  comment: string;
};

export type StartDriverShiftResponse = {
  service_start_driver_shift: {
    shift_id: string;
    time_log_id: string;
  };
};

const SET_DRIVER_ONLINE = gql`
  mutation StartShift(
    $driver_id: uuid!
    $set_by_account_external_reference: String = ""
    $duration_minutes: Int! = 480
    $type: String! = "online"
    $comment: String! = "8-hour online shift"
  ) {
    service_start_driver_shift(
      driver_id: $driver_id
      duration_minutes: $duration_minutes
      set_by: $set_by_account_external_reference
      shift_type: $type
      comment: $comment
    ) {
      shift_id
      time_log_id
    }
  }
`;

export type EndDriverShiftResponse = {
  service_end_driver_shift: {
    end_existing_active_timelogs: string;
    end_non_existing_active_timelogs: string;
  };
};

const SET_DRIVER_OFFLINE = gql`
  mutation EndShift($driver_id: uuid!) {
    service_end_driver_shift(driver_id: $driver_id) {
      end_existing_active_timelogs
      end_non_existing_active_timelogs
    }
  }
`;

export type ResumeDriverShiftResponse = {
  service_resume_driver_shift: {
    shift_id: string;
    time_log_id: string;
  };
};

const RESUME_DRIVER_WORK_FROM_BREAK = gql`
  mutation ResumeShift($driver_id: uuid!) {
    service_resume_driver_shift(driver_id: $driver_id) {
      shift_id
      time_log_id
    }
  }
`;

@Injectable()
export class DriversMutations extends GraphqlService {
  driversSubscription(): any {
    return this.deliverySubscription
      .subscribe({
        query: DRIVERS_SUBSCRIPTION,
        variables: {
          excluded_statuses: [
            "completed",
            "Completed",
            "cancelled",
            "Cancelled",
          ],
          today_start: getUserDayStart(),
        },
      })
      .filter((data) => !!data);
  }

  getDrivers(payload: any): any {
    return this.deliveryService.watchQuery({
      query: GetDrivers,
      variables: {
        tenantId: payload.tenantId,
        offset: payload.offset,
        limit: payload.limit,
      },
    });
  }

  insertDriver(payload: any): any {
    return this.deliveryService.mutate({
      mutation: InsertDrivers,
      variables: {
        first_name: payload.firstName,
        email: payload.email,
        last_name: payload.lastName,
        mobile_number: payload.mobileNumber,
        mobile_number_country_code: payload.mobileNumberCountryCode,
        photo_url: payload.photoUrl,
        drivers_license_number: payload.licenseNumber,
        address: payload.address,
      },
    });
  }

  getDriverAssignment(payload: any) {
    return this.deliveryService.watchQuery({
      query: GetDriverAssignment,
      variables: {
        id: payload.id,
      },
    });
  }

  assignDriver(payload: any): any {
    return this.deliveryService.mutate({
      mutation: AssignRider,
      variables: {
        id: payload.id,
        driver_id: payload.driver_id,
      },
    });
  }

  updateDriver(payload: any): any {
    return this.deliveryService.mutate({
      mutation: UpdateDrivers,
      variables: {
        id: payload.id,
        first_name: payload.firstName,
        email: payload.email,
        last_name: payload.lastName,
        mobile_number: payload.mobileNumber,
        mobile_number_country_code: payload.mobileNumberCountryCode,
        photo_url: payload.photoUrl,
        drivers_license_number: payload.licenseNumber,
        address: payload.address,
        start_time: payload.startTime,
        end_time: payload.endTime,
        vehicle_type: payload.vehicleType,
      },
    });
  }

  deleteItem(payload: any): any {
    return this.deliveryService.mutate({
      mutation: DeleteDriver,
      variables: {
        id: payload.id,
        deleted_at: payload.deleted_at,
      },
    });
  }

  setDriverOnBreak(driverId: string, minutes: number, dispatcherId: string) {
    return this.deliveryService.mutate<SetDriverOnBreakResponse>({
      mutation: SET_DRIVER_ON_BREAK,
      variables: {
        driver_id: driverId,
        duration_minutes: minutes,
        set_by_account_external_reference: dispatcherId,
      },
    });
  }

  setDriverOnline(request: StartDriverShiftRequest) {
    return this.deliveryService.mutate<StartDriverShiftResponse>({
      mutation: SET_DRIVER_ONLINE,
      variables: {
        ...request,
      },
    });
  }

  setDriverOffline(driverId: string) {
    return this.deliveryService.mutate<EndDriverShiftResponse>({
      mutation: SET_DRIVER_OFFLINE,
      variables: {
        driver_id: driverId,
      },
    });
  }

  resumeWorkFromBreak(driverId: string) {
    return this.deliveryService.mutate<ResumeDriverShiftResponse>({
      mutation: RESUME_DRIVER_WORK_FROM_BREAK,
      variables: {
        driver_id: driverId,
      },
    });
  }
}

<div class="Auth">
  <div class="Auth__inner">
    <div class="Auth__title-container">
      <!-- <div class="Auth__page-title-pre">WELCOME TO</div> -->
      <div class="Auth__page-title mat-display-3">Verify OTP</div>
    </div>
    <div class="Auth__form-container">
      <!-- <div class="Auth__form-title mat-display-1 text-center">
         Forgot Password
        </div> -->
      <form class="AuthForm" [formGroup]="otpForm">
        <!-- <mat-error *ngIf="!!errorMsg">
            {{ errorMsg }}
          </mat-error> -->

        <!-- <mat-form-field>
            <mat-label>Enter OTP</mat-label>
            <input
              matInput
              formControlName="email"
              placeholder="OTP"
             
            />
           
          </mat-form-field> -->
        <div class="form-group">
          <input
            class="form-control"
            type="text"
            placeholder="Enter code"
            formControlName="otp"
            (keypress)="phoneNoInput($event)"
            maxlength="4"
          />
          <mat-error
            *ngIf="isSubmitted && otpForm.controls['otp'].hasError('required')"
            >Otp is required</mat-error
          >
        </div>
        <button class="btn btn-primary" (click)="verifyOtp()">
          Verify OTP
        </button>
        <button class="btn btn-primary mt-2" (click)="forgotPwd()">
          Resend OTP
        </button>
        <p routerLink="/auth" class="loginpara">Back to Login</p>
      </form>
    </div>
  </div>
</div>

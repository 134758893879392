import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthComponent } from "./auth/auth.component";
import { AuthGuard } from "@shared/guards/auth.guard";
import { ForgotComponent } from "./auth/forgot/forgot.component";

import { ChangepasswordComponent } from "./auth/changepassword/changepassword.component";
import { OtpComponent } from "./auth/otp/otp.component";
const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "auth" },
  {
    path: "auth",
    component: AuthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "otp",
    component: OtpComponent,
  },
  {
    path: "changepassword",
    component: ChangepasswordComponent,
  },
  {
    path: "forgot",
    component: ForgotComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./console/console.module").then((m) => m.ConsoleModule),
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
// MODULES
import { SharedModule } from "@shared/shared.module";

import { HttpClientModule } from "@angular/common/http";
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from "@agm/core";
import { AppRoutingModule } from "./app-routing.module";

import { AuthGuard } from "@shared/guards/auth.guard";
import { CsvExporter } from "@shared/services/csv-exporter.service";
import { AppComponent } from "./app.component";
import { AuthComponent } from "./auth/auth.component";

import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";
import { ForgotComponent } from "./auth/forgot/forgot.component";

import { GetInterceptorService } from "src/app/_shared/services/get-interceptor/get-interceptor.service";
import { SetInterceptorService } from "src/app/_shared/services/set-interceptor/set-interceptor.service";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";

import { NgxSpinnerService } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChangepasswordComponent } from "./auth/changepassword/changepassword.component";
import { OtpComponent } from "./auth/otp/otp.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ForgotComponent,
    OtpComponent,
    ChangepasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // AngularFireModule,
    // AngularFireDatabaseModule,
    // AngularFireMessagingModule,
    AgmCoreModule.forRoot(),
    SharedModule,
    // GraphqlModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
  ],
  providers: [
    NgxSpinnerService,
    CsvExporter,
    AuthGuard,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Pipe, PipeTransform } from "@angular/core";

/**
 * Use a pipe to standardize the display of prices for the Bahraini Dinar.
 * Not doing so confuses the Bahraini user as the convention
 * for the currency is as follows:
 *
 * 1.20 = one dinar and 20 fils
 * 1.200 = one dinar and 200 fils
 * 1.2 = one dinar and 200 fils
 * 1.02 = one dinar and 2 fils
 * 1.020 = one dinar and 20 fils
 */
@Pipe({
  name: "fixDecimalPlace",
})
export class FixDecimalPlacePipe implements PipeTransform {
  transform(amount): string {
    // 3 decimal places specifically for bahrain

    const quantity = parseFloat(amount);

    if (!amount || Number.isNaN(quantity)) {
      return "0.000";
    }
    return quantity.toFixed(3);
  }
}
